﻿
@import '../../../resources/style/Colors.scss';
@import 'date_controls/DateControl.scss';


.record-date-picker {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/* LOADING SPINNER ICON */
.loading-spinner {
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    transform: scale(0.6);
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $text;
    margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Vertical Bar */

.vertical-bar.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    background-color: #ddd;
    box-shadow: $box-shadow;
    border-radius: 0.1em;
    overflow: hidden;
    margin: 0 0.25em;
}

/* Export Button */
.accent-btn {
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 10em;
    margin: 0 0.25em;
    color: $text;
    background-color: $tile-background;
    border: 0 solid transparent;
    border-bottom: 0.2em solid transparent;
    opacity: 0.7;
    box-shadow: $box-shadow;
    font-family: $font-family;
    transition: all 0.5s;

    &:hover {
        border-bottom: 0.2em solid $accent;
        color: $accent;
    }
}