﻿@import '../../resources/style/Colors.scss';

.site-page {
    .site-info {
        margin: 1.5em 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .site-owner, .site-group {
            height: 4em;
            display: flex;
            padding: 0.5em 1em;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin-right: 1em;
            background-color: $tile-background;
            box-shadow: $box-shadow;
            border-radius: 0.75em;

            i {
                font-size: 1.15em;
                margin-right: 0.5em;
                color: $text;
            }

            h4 {
                color: $text;
            }
        }
        .fleet-summary.tile {
            margin: 0;
            //margin: 0;
            //position: absolute;
            //top: -5em;
            //right: 0;
        }
    }   

}