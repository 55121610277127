﻿
.dx-item-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .context-item.box {
        height: 1em;
        width: 1em;
        margin-right: 0.35em;

        &.site {
            background-color: red;
        }

        &.inverter {
            background-color: yellow;
        }

        &.flywheel {
            background-color: blue;
        }
    }
}

