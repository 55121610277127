﻿@import "./ToggleTheme";

$status-good: hsla(120, 88%, 40%, 0.95);
$status-warning: hsla(50, 88%, 40%, 0.95);
$status-error: hsla(0, 88%, 40%, 0.95);
$status-info: hsla(200, 88%, 35%, 0.95);
$status-info-light: hsla(200, 88%, 35%, 0.5);
$status-info-accent: #2BC2F1 ;
$charge: hsl(170, 54%, 50%);
$discharge: hsl(30, 54%, 50%);

// Theme
$text: var(--text);
$accent: var(--accent);
$accent-light: var(--accent-light);
$background: var(--background);   
$tile-background: var(--tile-background);
$border: var(--border);
$box-shadow: var(--box-shadow);
$highlight: var(--highlight);

$font-family: Arial, Helvetica, sans-serif;
$gradient-accent: linear-gradient(to right, $accent-light, $accent);