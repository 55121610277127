@import '../../../resources/style/Colors.scss';

#theme-switch{
    display: flex;
    justify-content: center;
  }
  
  #theme-switch svg{
    color: $text;
  }
  
  #theme-switch .ball {
    box-shadow: $box-shadow;
  }
  
  #theme-switch .dark {
    background-color: #292c35;
  }
  #theme-switch .checkbox {
    opacity: 0;
    position: absolute;
  }
  
  #theme-switch .label {
    width: 33px;
    height: 17px;
    background-color:$background;
    display: flex;
    border-radius:50px;
    align-items: center;
    justify-content: space-between;
    padding: 3px 5px 3px 5px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: scale(1.5);
    cursor: pointer;
  }

  #theme-switch .label svg {
    width: 0.6em;
    height: 0.6em;
  }
  
  #theme-switch .ball {
    width: 14px;
    height: 14px;
    background-color: white;
    position: absolute;
    top: 1.5px;
    left: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  
  #theme-switch .checkbox:checked + .label .ball{
    transform: translateX(15px);
  }