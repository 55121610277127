﻿

#landing-page {
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(to right, #0e0e30, #2c3e50);
    display: flex;
    justify-content: center;
    align-items: center;

    .login-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 20em;

        img {
            width: 90%;
        }
    }
}