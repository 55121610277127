﻿@import '../../../resources/style/Colors.scss';

#searchable-dropdown {
    width: 18.5em;

    .dx-treeview-node {
        .dx-treeview-item {
            .dx-treeview-item-content {
                .dx-icon {
                    color: $text;
                    font-size: 1.75em;
                    margin-right: 0.5em;
                }
                
                span {
                    color: $text;
                    font-size: 1.1em;
                }

            }

            &:hover {
                background-color: transparent !important;
                
                .dx-treeview-item-content span {
                    color: $accent;
                }
            }

            &:focus {
                background-color: $tile-background !important;
            }
        }
    }

    .dx-treeview-toggle-item-visibility {
        color: $text !important;

        &:hover {
            color: $accent !important;
        }
    }
}
