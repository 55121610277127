﻿@import './site/SiteSummary.scss';
@import './array/ArraySummary.scss';
@import './flywheel/FlywheelSummary.scss';
@import '../../resources/style/Colors.scss';
@import '../../resources/style/Mixins.scss';

.fleet-summary-list-container {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        h2 {
            margin-top: 1em;
        }
    }

    .fleet-summary-list {
        display: flex;
        flex-direction: column;
    }
}

.fleet-summary-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    /*justify-content: ;*/

    h2 {
        margin: 0.25em;
        width: 100%;
    }
}

.fleet-summary {
    height: 10em;
    width: 32%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.25em 1em;
    margin: 0.5em;
    position: relative;

    .fleet-summary-menu {
        position: absolute;
        top: -0.25em;
        right: 0;

        .more-btn {
            background-color: transparent;
            outline: none;
            border: none;

            .dx-icon-more {
                font-size: 2em;
                &:hover {
                    color: $accent;
                }
            }
        }
    }
}

.dx-popup-content {
    overflow-y: auto;
}

.site-software-table {
    height: 40em !important;
}

@media only screen and (max-width: $phone-screen-max) {
    .fleet-summary {
        width: 100%;
        height: 10em;
        padding: 1em;
    }
}

@media only screen and (min-width: $phone-screen-max) and (max-width: $medium-screen-max) {
    .fleet-summary {
        width: 48%;
        height: 10em;
        padding: 1em;
    }
}