/* GLOBALS */
@import './resources/style/Base.scss';
@import './resources/style/Colors.scss';
@import './resources/style/Mixins.scss';
@import 'resources/icons/Icon.scss';

/* MAIN COMPONENTS */
@import 'components/landingpage//LandingPage.scss';
@import 'components/site/SitePage.scss';
@import 'components/array/ArrayPage.scss';
@import 'components/flywheel/FlywheelPage.scss';
@import 'components/rotor/RotorPage.scss';
@import 'components/fmr/FMR.scss';
@import 'components/worldmap/Worldmap.scss';
@import 'components/fleet/Fleet.scss';
@import 'components/historical//HistoricalPage.scss';
@import 'components/charts/AggregatePowerChart.scss';
@import 'components/charts/FlywheelComparisonChart.scss';
@import 'components/charts/ChartContextMenu.scss';
@import 'components/error/ErrorPage.scss';
@import 'components/error/ErrorMessage.scss';
@import 'azure/AzureAuthenticationComponent.scss';

/* CUSTOMER COMPONENTS */
@import 'components/customer/fleet/FleetNavigation.scss';
@import 'components/customer/site/SitePage.scss';

/* SHARED COMPONENTS */
@import 'components/shared/navigation/Navigation.scss';
@import 'components/shared/bread_crumb/BreadCrumb.scss';
@import 'components/shared/searchable_dropdown/SearchableDropdown.scss';
@import 'components/shared/reuseables/Reuseables.scss';
@import 'components/shared/loading/Loading.scss';

/* set max height */
#App {
    height: 100vh;
    max-height: 100vh;
    overflow-y: hidden;
    background-color: $background;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-container {
    display: flex;
    flex-direction: row;
}

.dx-toolbar {
    background-color: rgba(191, 191, 191, .15);
    padding: 5px 10px;
}

.dx-list-item-icon-container, .dx-toolbar-before {
    width: 36px;
    padding-right: 0px !important;
    text-align: center;
}

.dx-list-item-content {
    padding-left: 10px !important;
}

.dx-button {
    background-color: rgba(191, 191, 191, -0.15);
    border: none;
}

#content {
    overflow-y: auto;
    background-color: $background !important;
}

#toolbar {
    background-color: $tile-background !important;
}