﻿@import 'site_data_grid/SiteComponentDataGrid.scss';
@import 'site_info_tile/SiteInfoTile.scss';
@import 'site_readings_tile/SiteReadingsTile.scss';

.site-page-container {
    .site-page-tiles {
        display: flex;
        flex-direction: row;

        // Site Info Tiles
        .site-page-info-tile-group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 40%;
            height: 30em;
            margin: 0.5em 0;

            .site-info-tile, .site-readings-container {
                height: 47%;
                margin-top: 0;
                margin-bottom: 0.25em;
            }
        }

        // Site Component Data Grid
        .site-component-data-grid-container {
            width: 60%;
        }
    }

    .site-page-graphs {
        margin: 1em 0.5em 0.5em 0.5em
    }
} 