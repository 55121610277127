﻿@import '../../resources/style/Colors.scss';
@import '../../resources/style/ToggleTheme';

.tooltip {
    position: absolute !important;
	background-color: $background;
	width: 21em;
	padding: 0.7em;
	font-size: 0.7em;

	h4 {
		color: $text !important;
	}

	h5 {
		color: $text !important;
	}
}

.fault-tooltip {
    display: none;
    position: absolute !important;

    h4 {
        color: $text !important;
    }

    h5 {
        color: $text !important;
    }
}

#Aggregate-Chart-Group {
    position: relative;
    box-shadow: $box-shadow;
    background-color: $tile-background;
    padding: 2em;

    .flywheel-tooltip {
        color: beige !important;
    }

    .topbar {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;

        .loadingBars {
            position: absolute;
            left: 0;
            top: 2em;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            font-size: .8em;

            .loadingContainer {
                position: absolute;
                width: 15em;
                display: flex;
                align-items: center;
                vertical-align: central;
            }
        }
    }

    .time-zone {
        width: auto;
        position: absolute;
        right: 1em;
    }
}

.dxc-legend text,
.dxc-title text {
    fill: var(--chart-legend) !important;
}

.dxc-val-title text {
    fill: var(--chart-axis-labels) !important;
}
.dxc-arg-elements text,
.dxc-val-elements text {
    fill: var(--chart-axis-values) !important;
}

.dxc-val-grid,
.dxc-arg-grid {
    path {
        stroke: var(--chart-grid-lines) !important;
     }
} 

.dxc-val-axis,
.dxc-arg-axis {
    path {
        stroke: var(--chart-axis-lines) !important;
    }
}


