﻿

#authentication {
    width: 100%;

    .base-button {
        display: block;
        width: 100%;
        padding: 0.5em 1.5em;
        font-family: 'Lucida Grande', 'Helvetica', sans-serif;
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        border: none;
        border-radius: 1em;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        background-position: top center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        transition-property: background, color;
    }

    .login-button {
        position: relative;
        display: block;
        color: #F1682B;
        border-radius: 1em;
        box-sizing: border-box;
        border: 2px solid transparent;
        background-color: transparent;
        background-clip: padding-box;
        overflow: hidden;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            border: 2px solid transparent;
            background-image: linear-gradient(#0e0e30, #0e0e30), linear-gradient(90deg, #ff8e5b, #6942ef);
            background-clip: padding-box, border-box;
            background-repeat: repeat-x;
            background-size: calc(100% + 2px * 2) calc(100% + 2px * 2);
            background-position: center;
            border-radius: 1em;
            z-index: -1;
            transition: border-color 0.2s;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 70vmax;
            height: 70vmax;
            border-radius: 50%;
            background-image: linear-gradient(to right, #F1682B, #ef8c42);
            transform-origin: center;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 0.4s ease-in-out;
            z-index: -1;
        }

        &:hover {
            color: #eee;

            &::after {
                transform: translate(-50%, -50%) scale(1);
            }
        }

        &:active {
            color: #c3c4d5;
        }

        &:focus {
            color: white;

            &::before {
                border-color: #F1682B;
            }
        }
    }

    .logout-button {

    }
}

@keyframes gradientRotate {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 100% 100%;
    }
}
