﻿@import '../../resources/style/Colors.scss';

.flywheel-comparison-chart {
    box-shadow: $box-shadow;
    background-color: $tile-background;
    padding: 2em;
    margin: 2em 0;
    position: relative;

    h5 {
        color: azure !important;
    }

    .topbar {
        display: flex;
        flex-direction: column;

        .control-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            height: 3em;

            .loading-bars {
                display: flex;
                flex-direction: row;
                width: 40%;
                display: flex;
                width: 30em;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-start;
                font-size: .8em;

                .loading-container {
                    width: 15em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .record-date-picker {
                //width: 30%;
            }
        }

        .tag-box-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding: 0.1em 0;

            .tag-box {
                height: 3em !important;
                width: 49.9% !important;
                
                .dx-placeholder {
                    color: $text;
                }
            }
        }
    }

    .time-zone {
        width: auto;
        position: absolute;
        right: 1em;
    }
}