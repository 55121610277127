﻿@import '../../../../resources/style/Colors.scss';

.site-component-data-grid {

    // override devextreme theme
    .dx-toolbar {
        background-color: #2c3e50 !important;
    }

    .array-serial-cell span {
        text-decoration: none;
        color: $text;
    }

    .flywheel-serial-cell a {
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        color: $status-info-accent !important;
    }

    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        
        .icon {
            height: 1.5em;
        }
    }
}