﻿@import '../../../resources/style/Colors.scss';
@import '../../../resources/style/Mixins.scss';
@import './SiteDetail.scss';

.site-container {
    width: 60%;
    height: 100%;

    .info-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;

        .summary-info {
            width: 50%;

            h1 {
                font-size: 1.5em;
                overflow-wrap: break-word;

                a {
                    color: $accent;
                    text-decoration: none;
                    border-bottom: 1px solid transparent;
                    opacity: 0.7;
                    transition: 0.5s all;

                    &:hover {
                        opacity: 1;
                        border-bottom: 1px solid darkorange;
                    }
                }

                .power-icon {
                    height: 0.85em;
                    width: 0.85em;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            h4 {
                font-size: 0.85em;
            }

            h5 {
                font-size: 0.75em;
            }

            h4, h5 {
                /*font-size: 0.85em;*/
                margin: 0;
            }

            .button-group {
                .icon {
                    fill: $text !important;
                }
            }
        }
    }

    .summary-readings {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        h3 {
            font-size: 1em;
            margin: 0;
        }

        button {
            border: none;
            box-shadow: none;
            background-color: transparent;

            .info-icon {
                fill: #ddd;
                height: 1.5em;
                width: 1.5em;
                opacity: 0.5;
                transition: 0.5s all;
            }

            &:hover {
                cursor: pointer;

                .info-icon {
                    opacity: 1;
                }
            }
        }

        .readings-info {
            display: flex;
            justify-content: flex-end;
            font-size: .85em;
            margin: 0;
            padding: 0 0.5em;
            height: 100%;
            width: 100%;

            span {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                align-items: flex-start;
                font-weight: bold;
                font-size: 1.5em;

                .unit {
                    width: 3em;
                    display: flex;
                    justify-content: flex-start;
                    font-size: 1em;
                    margin-left: 0.25em;
                }

                .icon {
                    width: 0.65em;
                    margin: 0 0.15em;
                    /*margin: 0 0.25em;*/
                    &.up-arrow-icon {
                        fill: $charge;
                    }

                    &.down-arrow-icon {
                        fill: $discharge;
                    }
                }
            }

            
        }
    }

    ul.summary-notification {
        list-style: none;

        .alert {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .icon {
                height: 1.25em;
                width: 1.25em;
                margin-right: 0.5em;
            }

            .icon.exclamation-icon {
                fill: $status-error;
            }

            .icon.info-icon {
                fill: $status-warning;
                opacity: 0.8;
            }
        }
    }
}
