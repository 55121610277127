﻿@import '../../../resources/style/Colors.scss';

.summary-flywheel-list {
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 40%;
    height: 100%;
    padding: 0.1em 0;

    .content-container {
        min-height: 2.25em;
        margin: 0.75em 0;
        position: relative;
        border: 0.1em solid $border;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 0.25em;

        .array-link {
            top: -0.80em;
            position: absolute;
            text-align: left;
            border: transparent;
            background-color: $tile-background;
            font-size: 0.85em;
            margin: 0 0.25em;

            &:hover {
                border: none;
            }
        }

        .flywheel-link {
            font-size: 1.25em;
            margin: 0 0.25em;

            &:hover {
                // border-bottom: 1px solid $status-info;
                border-bottom: 1px solid $accent;
                opacity: 1;
            }
        }
    }
}

.link {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.15em;
	font-weight: bold;
	margin: 0.1em;
	text-decoration: none;
	// color: $status-info;
	color: $text;
    border-bottom: 1px solid transparent;
	// opacity: 0.7;
	transition: all 0.25s;

    .icon {
        // width: 1em;
    }

	&.flywheel-link:hover {
		opacity: 1;
        // color: $status-info;
        color: $accent;
	}

    &.array-link {
        color: $text;
    }

	//&.inactive {
	//    color: $status-error;
	//}
}