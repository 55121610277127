﻿
// rotor page

// rotor table
.rotor-id-cell {
    &:hover {
        color: #03A9F4;
        font-weight: bold;
        cursor: pointer;
    }
}

// rotor installation modal