﻿@import '../../../resources/style/Colors.scss';

.fleet-navigation-container {
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
    a {
        text-decoration: none;
    }
    
    .fleet-navigation-tile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 28em;
        height: 4em;
        border-top: none;
        border-right: none;
        border-bottom: none;
        border-left: 1em solid transparent;

        h1.tile-name {
            font-size: 1.5em;
            overflow-wrap: break-word;
            color: $accent-light;
            text-decoration: none;
            text-align: left;
            border-bottom: 1px solid transparent;
            opacity: 0.7;
            width: 50%;
        }

        .tile-readings {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            color: $text;

            div {
                font-size: 1.25em;
                font-weight: bold;
                margin: 0 0.25em;
                span {
                    margin: 0.1em;
                }
            }
        }

        &:not(.tile-active):hover {
            border-left: 1em solid $accent;
            transition: 0.5s all;

            h1 {
                color: $accent;
            }
        }
    }

    .fleet-navigation-tile.tile-active {
        h1 {
            color: $accent;
        }

        border-left: 1em solid $accent;
    }
}