﻿@import '../../../../resources/style/Colors.scss';

.site-readings-container {
    padding: 1em;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;

    .title {
    }

    .readings-tile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 50%;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;

        .title {
            position: absolute;
            align-self: flex-start;
            height: 15%;
            font-size: 1.25em;
            padding: 0.5em;
        }

        .reading-container {
            display: flex;
            justify-content: center;
            align-content: center;
            height: 100%;
            width: 100%;

            .reading {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .icon-container {
                    display: flex;
                    justify-content: center;
                    align-content: flex-start;
                    margin: 0 0.1em;
                    .icon {
                        height: 2.5em;
                        width: 2.5em;
                        fill: red;

                        &.up-arrow-icon {
                            fill: $charge;
                        }

                        &.down-arrow-icon {
                            fill: $discharge;
                        }
                    }
                }

                .value {
                    font-size: 2.25em;
                    text-align: right;
                    margin-right: 0.1em;
                }

                .unit {
                    font-size: 2.25em;
                }
            }
        }

        .soc-visual-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            #soc-visual {
                width: 100%;

                .dxg-circular-gauge {
                    transform: scale(0.80);
                }
            }
        }
    }


    .timestamp {
        position: absolute;
        left: 0;
        bottom: 0.25em;
        font-size: 0.5em;
    }
}
