﻿
@import '../../resources/style/Colors.scss';

.ui-accordion-content.medical-records-container {
    padding: 0 !important;
}

.hashtags-container:hover {
    cursor: pointer;
}   

span.hashtag {
    margin: 0.5em;
    padding: 0.5em;
    border-radius: 1em;
    background-color: $accent-light;
    color: $text;
}

span.hashtag:hover {
    cursor: pointer !important;
}

.download-link {
    color: hsla(199, 99, 48, 0.8) !important;
}

#record-date-picker {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1em;
}

#record-start-date, #record-end-date {
    margin-left: 1em;
}

.fmr-container {
    display: flex;
    flex-direction: column;
    width: 97vw;
}

.fmr-container .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .date-range-switch {
        transform: scale(1.5);
        margin-right: 0.75em;
        margin-left: 0.75em;
    }
}

.fmr-container .fmr-search {
    align-self: flex-end;
}

.txt-anchor {
    background: transparent;
    border: none;
    outline: 0;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}

.load-more-btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

td.fmr-favorite.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
    content: none !important;
}