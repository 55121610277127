﻿@import '../style/Colors.scss';

.down-arrow-icon, .up-arrow-icon {
    width: 1.15em;
    height: 1.15em;
}

.power-icon {
    height: 1.5em;
    width: 1.5em;
}

.active-icon, .stop-icon, .spin-icon, .question-mark-icon {
    height: 0.65em;
}

.active-icon {
    fill: $status-info;
}

.spin-icon {
    margin-right: 0.1em;
    stroke: darkorange;
    fill: darkorange;
}

.stop-icon {
    fill: $text;
    height: 0.65em;
    margin-right: 0.1em;
}

.question-mark-icon {
    fill: $status-error;
}