﻿@import '../../../resources/style/Colors.scss';

.site-detail-container {
    padding: 1em;

    h1 {
        color: $text;
    }

    ul.site-details {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        list-style: none;

        li {

            overflow: hidden;
            box-shadow: $box-shadow;
            border: 0.1em solid $border;
            border-radius: 0.5em;
            margin: 1em;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 3em;
            width: 22em;
            //background-color: $text;

            i {
                height: 100%;
                width: 2em;
                padding: 0.25em;
                font-size: 1.75em;
                color: $accent-light;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $background;
            }

            span {
                height: 100%;
                padding: 0 0.5em;
                font-size: 1.15em;
                background-color: $tile-background;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}