﻿@import '../../../../resources/style/Colors.scss';

.site-info-tile {
    position: relative;
    padding: 1em;
    h1.title {
        color: $accent;
    }

    h3.location {
        font-weight: bold;
        color: $text;
    }

    .site-attributes {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 1em 0;
        height: 45%;

        .site-attribute {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .property {
                width: 25%;
                font-size: 0.95em;
                font-weight: bold;
                opacity: 35%;
            }

            .value {
                font-weight: bold;
            }
        }
    }

    .fleet-summary-menu {
        position: absolute;
        top: -0.25em;
        right: 0;

        .more-btn {
            background-color: transparent;
            outline: none;
            border: none;

            .dx-icon-more {
                font-size: 2em;

                &:hover {
                    color: $accent;
                }
            }
        }
    }
}
