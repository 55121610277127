﻿@import '../../resources/style/Colors.scss';

.map-container {
    height: 100%;
    padding: 1em 0;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .map {
        align-self: center;

        .MicrosoftMap > div {
            overflow: hidden;
            border-radius: 2em;
            box-shadow: $box-shadow;
        }
    }

    #ZoomInButton, #ZoomOutButton {
        display: block !important;
    }

    .Infobox, .InfoboxCustom {
        height: 10em;
        width: 30em;
        border: $border;
        border-radius: 1em !important;
        box-shadow: $box-shadow;
        background-color: $tile-background;
        overflow: hidden;
    }

    .InfoboxCustom div .world-map-tooltip {
        color: #000 !important;
        background-color: $background;

        button {
            background-color: $background;
        }
        .close-btn {
            background-color: $background !important;
            color: $text;
            font-weight: bold;
            border: none;
            width: 3em;
            align-self: flex-end;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .infobox-info, .infobox-body {
        max-height: none !important;
        max-width: none !important;
        height: inherit !important;
        width: inherit !important;
        padding: 0 !important;
        background-color: transparent;

        & > div {
            height: inherit;
            width: inherit;
            background-color: transparent;
        }
    }

    .infobox-stalk {
        display: none;
    }

    .world-map-tooltip {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: inherit;
        padding: 0;
        background-color: transparent;
        box-shadow: $box-shadow;
        font-size: 0.7em;

        h4 {
            color: $accent;
        }

        h5 {
            color: $accent;
        }

        h1, h3, h4 {
            color: $accent;
        }

        .info-container {
            padding: 1.5em;
            margin: 0;
            width: 100%;
            height: 80%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            .info {
                width: 50%;

                h1 {
                    color: $accent;
                    font-size: 2.5em;
                }

                h4 {
                    color: $text;
                    font-size: 1em;
                }
            }

            .readings {
                width: 50%;
                align-self: flex-end;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                h3 > span {
                    font-size: 2em;
                }

                h3 {
                    color: $text;
                }
            }
        }

        .nav-btn {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            padding: 0;
            border: none;
            color: #000;
            font-weight: bold;
            height: 3em;
            font-size: 1.25em;
            transition: all 0.5s;
            background-image: $gradient-accent;

            &:hover {
                background-position-x: 34em;
                cursor: pointer;
            }
        }
    }
}
