﻿@import '../../../resources/style/Colors.scss';

#breadcrumb-container {
    list-style: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    overflow: hidden;
    width: 100%;
    padding: 0;

    li {
        float: left;
        margin: 0 .5em 0 1em;
    
        a i {
            font-size: 1.25em;
        }    
    }
}

// active breadcrumb
#breadcrumb-container li.active {
    //body
    a {
        color: $background;
        background: $gradient-accent;
        //left arrow
        &::before {
            border-color: $accent-light $accent-light $accent-light transparent;
        }
        //right arrow
        &::after {
            border-left-color: $accent;
        }
    }
}

#breadcrumb-container a {
    background: $accent-light;
    padding: .7em 1em;
    float: left;
    text-decoration: none;
    font-weight: bolder;
    color: $background;
    position: relative;
    height: 100%;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -1.5em;
        border-width: 1.5em 0 1.5em 1em;
        border-style: solid;
        border-color: $accent-light $accent-light $accent-light transparent;
        left: -1em;
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -1.5em;
        border-top: 1.5em solid transparent;
        border-bottom: 1.5em solid transparent;
        border-left: 1em solid $accent-light;
        right: -1em;
    }
}

#breadcrumb-container a:hover {
    color: $background;
    background-color: $accent;
    transition: 0.5s all;

    &::before {
        border-color: $accent $accent $accent transparent;
        transition: 0.5s all;
    }

    &::after {
        border-left-color: $accent;
        transition: 0.5s all;
    }
}

#breadcrumb-container .current,
#breadcrumb-container .current:hover {
    font-weight: bold;
    background: none;
}

#breadcrumb-container .current::after,
#breadcrumb-container .current::before {
    content: normal;
}
