﻿@import '../../resources/style/Colors.scss';

.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;

    .error-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $tile-background;
        padding: 1.5em;
        border-radius: 1em;
        box-shadow: 0.1em 0.1em 0.5em $box-shadow;

        h1 {
            color: $text;
            font-size: 8em;
            margin: 0;
            margin-right: 0.25em;
            padding: 0;
        }

        div {
            height: 6em;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            h4 {
                //width: 2.5em;
                color: $status-info-accent;
                //border-bottom: 0.2em solid $status-info;
            }

            p {
                color: $text;
                font-size: 0.85em;
            }

            button {
                border: none;
                padding: 0.5em 1.5em;
                color: #eee;
                background-color: $status-info;
                border-radius: 0.5em;
                box-shadow: 0.1em 0.1em 0.5em $box-shadow;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}