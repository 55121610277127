﻿@import './Colors.scss';

// variables

$phone-screen-max: 60em;
$medium-screen-max: 100em;



// classes

.tile {
    margin: 0.5em;
    padding: 0 0.5em;
    border-radius: 0.25em;
    box-shadow: $box-shadow;
    background-color: $tile-background;
}

.blinkingSVG {
    animation: blinkingSVG 0.8s infinite;
}

@keyframes blinkingSVG {
    0% { fill: $accent; }
    25% { fill: transparent }
    50% { fill: transparent }
    75% { fill: transparent }
    100% { fill: $accent; }
}

// scss mixins