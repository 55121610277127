﻿@import '../../resources/style/Colors.scss';

.error-message-popup {

    .error-message {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .dx-icon-clear {
            color: $status-error;
            font-size: 3.5em;
            margin-right: 0.25em; 
        }

        p {
            font-weight: 400;
        }
    }

}