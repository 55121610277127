﻿
.historical-page {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    // Flywheel Page Section
    .flywheel-list {
        .flywheel-serial-cell {
            a {
                text-decoration: none;
                color: inherit;
            }
            &:hover {
                color: #03A9F4;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
    // Site Page Section
}
