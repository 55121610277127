﻿@import '../../../resources/style/Colors.scss';

.summary-array-list {
    width: 30%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .array-link {
        margin: 0.75em;
        color: $text;
    }
}
