﻿@import '../../../resources/style/Colors.scss';

 // NAVIGATION 
.navigation:active {
    background-color: $tile-background;
}

.navigation-list {
    border-right: 0.1em solid $border;
}

.dx-drawer-panel-content {
    width: 20em;
}

.panel-list {
    height: 100vh;
    background-color: $tile-background !important;
    overflow-y: auto;
}

.dx-drawer-expand.dx-drawer-right .panel-list {
    float: right;
}

.panel-list:active {
    background-color: none;
}

.panel-list a {
    text-decoration: none;
}

.panel-list a .dx-list-item {
    color: $text;
    font-size: 15px;
}

.panel-list a .dx-list-item .dx-icon {
    color: $text !important;
    font-size: 1.75em;
}

.panel-list .dx-list-item-content:hover {
    .dx-icon {
        color: $accent !important;
    }
    color: $accent;
}

.options {
    padding-left: 20px;
    background-color: rgba(191, 191, 191, .15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
    display: inline-block;
    margin-right: 50px;
}

label {
    font-weight: bold;
}

#content {
    height: 100%;
    padding: 10px 20px;
}

#content h2 {
    font-size: 26px;
}

#main-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 3em;
}

.time-zone-container {
    width: 100%;
    margin: 0;
    padding: 0 1em;

    .time-zone {
        font-size: 0.85em;
    }
}