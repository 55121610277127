﻿@import '../../resources/style/Colors.scss';

.flywheel-page {
    display: flex;
    flex-direction: column;
    .flywheel-info {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.5em;

        .software-btn {
            text-transform: uppercase;
        }

        .flywheel-software {
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: $box-shadow;
            border-radius: 0.75em;
            max-width: 20em;
            padding: 0.5em 1em;
            background-color: $tile-background;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.flywheel-info-modal {
    list-style: none;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        span {
            color: $text;
            font-weight: 600;
            font-size: 1.25em;
        }
    }
}

.flywheel-rotor {
    width: 100%;
    min-height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;

    .message, #large-indicator {

    }

    ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        li {
            overflow: hidden;
            box-shadow: $box-shadow;
            border: 0.1em solid $border;
            border-radius: 0.5em;
            margin: 1em;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 3em;
            width: 25em;

            span.title {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 5em;
                padding: 1em;
                font-size: 1em;
                font-weight: bold;
                color: $accent-light;
                background-color: transparent;
                background-color: $background;
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: 0 0.5em;
                font-size: 1.15em;
                background-color: $tile-background;
            }
        }
    }
}