﻿@import './Colors.scss';

html, body {
}

h1, h2, h3, h4, h5, h6, p, button {
    margin: 0;
    padding: 0;
    color: $text;
    font-family: $font-family;
}

// devextreme overrides
.dx-state-active {
    background-color: $tile-background !important;

    &:active {
        background-color: $tile-background !important;

    }
}

// todo check for highlights 
.dx-item.dx-treeview-item {
    background-color: $tile-background !important;

    * {
        background-color: $tile-background !important;
    }
}

.dx-list:not(.dx-list-select-decroator-enabled) .dx-list.dx-state-active {
    background-color: red !important;
}