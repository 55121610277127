body,
body[data-theme="light"] {
    --text: #153D61;
    --accent: #E8863F;
    --accent-light: #EFAA78;
    --background: #F9F9F9;
    --tile-background: #FFFFFF;
    --button-hover: #99ABBE;
    --border: rgba(255, 255, 255, 0.8);
    --box-shadow: 0.15em 0.15em 0.5em rgba(0, 0, 0, 0.2);
    --highlight: none;
    --chart-grid-lines: #99ABBE;
    --chart-axis-lines: #153D61;
    --chart-axis-labels: #153D61;
    --chart-axis-values: #153D61;
    --chart-axis-labels: #153D61;
    --chart-legend: #153D61;
    --tagbox-background: #F9F9F9;
    --tagbox-background-hover: #FFFFFF;
    --tagbox-border: 1px solid #153D61;
    --tagbox-border-hover: 1px solid #EFAA78;
    --comparison-chart-tagbox-text: rgba(161,187,213,.6);
    --datarow-background: #F9F9F9;
    --datarow-alt-background: #F9F9F9;
    --pagination-page-selected-text: #FFFFFF;
    --pagination-page-selected-background: #E8863F;
    --pagination-page-hover-background: #FFFFFF;
    --icons-glyphs: rgba(0,0,0,.54);
}

    body[data-theme="dark"] {
        --text: #A1BBD5;
        --accent: hsl(18, 88%, 56%);
        --accent-light: hsla(18, 88%, 56%, 0.35);
        --background: #192A3B;
        --tile-background: #2C3E50;
        --button-hover: #3A526A;
        --border: rgba(0, 0, 0, 0.2);
        --box-shadow: 0.15em 0.15em 0.5em rgba(0, 0, 0, 0.2);
        --highlight: none;
        --chart-grid-lines: rgb(81, 81, 89);
        --chart-axis-lines: rgba(255,255,255,0.54);
        --chart-axis-values: rgba(255,255,255,0.54);
        --chart-axis-labels: #FFFFFF;
        --chart-legend: #FFFFFF;
        --tagbox-background: #425D77;
        --tagbox-background-hover: #476481;
        --tagbox-border: none;
        --tagbox-border-hover: none;
        --comparison-chart-tagbox-text: rgba(161,187,213,.6);
        --datarow-background: #2C3E50;
        --datarow-alt-background: #33485D;
        --pagination-page-selected-text: rgba(0,0,0,.87);
        --pagination-page-selected-background: #F2672C;
        --pagination-page-hover-background: rgba(255,255,255,.05);
        --icons-glyphs: rgba(255,255,255,.54);
    }